.cy-context-menus-cxt-menu {
    display:none;
    z-index: 1000;
    position:absolute;
    border:1px solid #A0A0A0;
    padding: 0;
    margin: 0;
    width:auto;
}

.cy-context-menus-cxt-menuitem {
    display:block;
    width: 100%;
    padding: 3px 20px;
    position:relative;
    margin:0;
    background-color:#f8f8f8;
    font-weight:normal;
    font-size: 12px;
    white-space:nowrap;
    border: 0;
    text-align: left;
}

.cy-context-menus-cxt-menuitem:enabled {
    color: #000000;
}

.cy-context-menus-ctx-operation:focus {
  outline: none;
}

.cy-context-menus-cxt-menuitem:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #0B9BCD;
    background-image: none;
    cursor: pointer;
}

.cy-context-menus-cxt-menuitem[content]:before {
    content:attr(content);
}

.cy-context-menus-divider {
  border-bottom:1px solid #A0A0A0;
}

.cy-context-menus-submenu-indicator {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
}