.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #d1cccc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-time {
  font-size: calc(5px + 1vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.mainContent {
  margin: 100px 60px 0px 60px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.linkEntryChildHierarchy {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.tableInfoUserPage {
  margin-bottom: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.emptyLogo {
  width: 200px;
  height: 48px;
  float: left;
  margin: 16px 50px 16px 0;
}

.header_logo_adaptive img {
  max-width: 200px;
  max-height: 48px;
  object-fit: contain;
  float: left;
  margin: 16px 50px 16px 0;
  line-height: 0px;
}

.burger {
  float: right;
  margin-right: 40px;
  margin-left: 20px;
}

.entrySubFolderCard {
  text-align: center;
  width: 200px !important;
  height: 180px;
  margin: 5px;
}

.entrySubFolderEditPosition {
  width: 200px;
  display: inline-block;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  margin: 5px;
}

.textFormBpmn {
  font-size: 1vw;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  overflow: hidden;

}

.subTitleQueryResult {
  color: black;
  font-size: 1.4vh;
}

.setupEntryType {
  display: flex;
}

.setupEntryTypeBtnAdd {
  margin-right: 5px;
}

.setupEntryTypeSearch {
  width: 75%;
}

.searchForSetup {
  display: flex;
}


.scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(196, 196, 196);
}

.scrollable::-webkit-scrollbar-button {
  display: none;
}

.EntryChildHierarchy::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.EntryChildHierarchy::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(196, 196, 196);
}

.EntryChildHierarchy::-webkit-scrollbar-button {
  display: none;
}

.iconHand {
  float: right;
  border-left: solid #efebeb 1px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.documentViewerPanel {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: rgb(0, 0, 0);
  padding: 10px 10px 8px;
  left: 50%;
  border-radius: 20px;
  transition: opacity 600ms ease-in-out 0s;
  bottom: 20px;
  transform: translate(-50%, 0px);
}

.layoutDescription {
  background-color: white;
  /* padding: 10px; */
  max-width: 1300px;
}

.layoutDescription div.react-grid-item:not(.react-grid-placeholder) {
  background: transparent;
  border: 2px dashed rgb(150, 150, 150);
  padding: 5px;
  overflow: hidden;
}

.froala-editor-container {
  width: 100%;
  height: 100%;
}

.froala-editor-custom {
  width: 100%;
  height: 100%;
}

.modalEditDescription .ant-modal-body {
  background-color: #e7e7e7;
  padding: 0px 10px 10px 10px;
  overflow-y: scroll;
  overflow-x: visible;
  max-height: calc(95vh - 210px);
}

.react-grid-item img {
  pointer-events: auto !important;
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.fr-popup {
  z-index: 2147483635 !important;
}

.froala-editor-container .fr-toolbar {
  border: 0px;
}

.backup-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.backup-content:hover {
  border: 1px dashed #40a9ff;
}

.groupRightsTable {
  margin-left: 50px;
}

.toolPanel {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100000;
  margin-bottom: 10px;
  /* padding: 0px 0px 16px 0px;  */
  text-align: end;
  /* border: 1px solid #CCCCCC; */
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.4);
}

.mainFroala .fr-box.fr-basic .fr-element {
  padding: 0px !important;
}

.mainFroala .fr-box.fr-basic .fr-wrapper {
  border: 0px !important;
}

.toolPanel .fr-toolbar.fr-top {
  border-radius: 0px !important;
  border: 1px solid #ffffff !important;
}

.saveButton {
  position: absolute !important;
  top: 9px;
  right: 150px;
  z-index: 1;
  color: black;
}

.fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
  display: none;
}

.ant-form-item-control-input-content .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
  display: block !important;
}

.descriptionSetup {
  padding-bottom: 10px;
}

.dashboardCount {
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  width: 20px;
  height: 20px;
}

.empty-span-translate{
  display: none;
}

.avatarPopover{
  z-index: 10050;
}

@media print {

  /*Pro Tisk*/
  body {
    font-family: Arial, Helvetica, sans-serif;
    color: black;
  }

  .ant-layout header {
    display: none;
    padding: 0, 0, 0, 0;
  }

  .mainContent {
    margin: 0;
    padding: 0px;
  }

  .ant-page-header-heading-extra {
    display: none;
  }

  .ant-row.ant-row-bottom {
    display: none;
  }

  .attSectionEmpty4Print {
    display: none;
  }

  .entryViewRelationPrint {
    display: none;
  }

  .ant-layout-footer {
    display: none;
  }

  .ant-divider.ant-divider-horizontal {
    display: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 0px;
    font-variant: tabular-nums;
    line-height: 0;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    border-top: 0px;
  }

  .entryAttDividerPrint {
    display: none;
  }

  .entryPageHeader {
    font-size: 1px;
    line-height: 0;
  }

  .ant-page-header-heading-title {
    font-size: 1px;
    line-height: 0;
    overflow: visible;
    text-overflow: none;
    white-space: pre-wrap;
  }

  .ant-page-header-heading-left {
    overflow: visible;
  }

  .mainContent {
    margin-top: 0px !important;
  }

  .ant-page-header {
    padding: 0px !important;
  }

  .ant-divider {
    display: none !important;
  }

  .documentViewerPanel {
    display: none;
  }

}

@media(min-width:1920px) {
  .attributeLabel {
    font-size: 16px;
  }
}

@media (max-width:1550px) {
  .groupsCard .ant-card-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width:1470px) {
  .mainContent {
    margin: 90px 0px 0px 0px;
    padding: 12px;
  }

  .ant-page-header {
    padding: 16px 12px !important;
  }
}

@media (max-width:1250px) {
  .groupsCard .ant-card-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mainRowGroup {
    flex-flow: column !important;
  }

  .colGroup {
    max-width: 100% !important;
  }

  .groupRightsTable {
    margin-left: 0px;
  }
}

@media (max-width:1024px) {
  .ant-form-item-label {
    overflow: visible !important;
  }

  .generalFormRadioBtn {
    float: right;
  }

  .generalFormCheckBox {
    margin-left: 130px !important;
  }

  .textFormBpmn {
    font-size: 3vw;
  }

  .mainContent {
    margin: 0;
    margin-top: 90px;
    padding: 12px;
  }

  .ant-page-header-heading-extra {
    white-space: pre-line;
  }

  .lineageLegend {
    right: 24px;
    bottom: 24px;
  }

  .setupEntryType {
    display: block;
  }

  .setupEntryTypeSearch {
    display: flex;
    width: 75%;
  }

  .advancedSearchRow {
    border: 3px solid #91d5ff;
    border-top: 1px dashed #91d5ff;
    border-bottom: 1px dashed #91d5ff;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 50px;
    max-width: 100%;
  }

  .advancedSearchAddbuttons {
    flex-direction: column;
  }

  .advancedSearchRow section {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }

  .advancedSearchRow main {
    padding: 7px 6px;
    /*border-left: 2px dashed #91d5ff;*/
  }

  .advancedSearchDescBox th {
    width: 20%;

  }

  .searchFormItem {
    width: 90%;
  }

  .advancedSearchOperand {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .advancedSearchAddbuttons {
    margin-top: 10px;
  }

  .attSectionRowGutter {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 0px;
  }

  .setupActionBtn {
    display: none !important;
  }

  .searchForSetup {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width:800px) {
  .groupsCard .ant-card-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .textFormBpmn {
    font-size: 5vw;
  }

  .entryPageHeader .ant-page-header-heading-title {
    font-size: 5vmin !important;

  }

  .ant-page-header-heading-sub-title {
    display: none !important;
  }

  .mainContent {
    margin: 0;
    margin-top: 90px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .toolsPanelBtnText {
    display: none !important;
  }

  .ant-page-header {
    padding: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .entrySubFolderCard {
    text-align: center;
    width: 125px !important;
    height: 120px;
    margin: 5px;
  }

  .lineageLegend {
    right: 10px;
    bottom: 10px;
  }

  .subTitleQueryResult {
    color: black;
    font-size: 2.5vh;
  }

  .setupEntryType {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
  }

  .advancedSearchRow {
    border: 3px solid #91d5ff;
    border-top: 1px dashed #91d5ff;
    border-bottom: 1px dashed #91d5ff;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 50px;
    max-width: 100%;
  }

  .advancedSearchAddbuttons {
    flex-direction: column;
  }

  .advancedSearchRow section {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }

  .advancedSearchRow main {
    padding: 7px 6px;
    /*border-left: 2px dashed #91d5ff;*/
  }

  .advancedSearchDescBox th {
    width: 20%;

  }

  .searchFormItem {
    width: 90%;
  }

  .advancedSearchOperand {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .advancedSearchAddbuttons {
    margin-top: 10px;
  }

  .attSectionRowGutter {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 0px;
  }

  .steps-content {
    background: transparent;
    border: 0px;
    padding: 0px;
  }

  .steps-content .ant-card-bordered {
    border: 0px !important;
  }

  .steps-content .ant-card-body {
    padding: 0px !important;
  }
}

@media (max-width:650px) {

  .groupTransfer {
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    ;
  }

  .groupsCard .ant-card-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .groupTransfer .anticon-right {
    transform: rotate(90deg);
    display: inline-block;
  }

  .groupTransfer .anticon-left {
    transform: rotate(90deg);
    display: inline-block;
  }
}

@media (max-width:425px) {
  .groupTransfer .ant-transfer-list {
    width: 240px !important;
  }

  .groupsCard .ant-card-head {
    font-size: 14px;
    padding: 0px 5px;
  }
}

@media (max-width:360px) {
  .header_logo_adaptive img {
    width: 170px;
    height: 48px;
  }

  .entrySubFolderCard {
    text-align: center;
    width: 120px !important;
    height: 120px;
    margin: 2px;
  }

  .subTitleQueryResult {
    color: black;
    font-size: 2.5vh;
  }

  .setupEntryType {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
  }

  .advancedSearchAddbuttons {
    flex-direction: column;
  }
}