.froalaCustomInformation {
    background-color: #f6ffed;
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border-left: solid 4px #95de64;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}

p.froalaCustomInformation {
    background-image: url(../src/images/froalaInfo.png);
}

.froalaCustomTip {
    background-color: #fffbe6;
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border-left: solid 4px #ffd666;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}

p.froalaCustomTip {
    background-image: url(../src/images/froalaTip.png);
}

.froalaCustomNote {
    background-color: #e6f7ff;
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border-left: solid 4px #69c0ff;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}

p.froalaCustomNote {
    background-image: url(../src/images/froalaNote.png);
}

.froalaCustomWarning {
    background-color: #fff1f0;
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border-left: solid 4px #ff7875;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}

p.froalaCustomWarning {
    background-image: url(../src/images/froalaWarning.png);
}


/* 
.froalaCustomInformationOld {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    background-color: #f6ffed;
    background-image: url(../src/images/froalaInfo.png);
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border: solid 1px #95de64;
    border-radius: 6px;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}
.froalaCustomTipOld {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    background-color: #fffbe6;
    background-image: url(../src/images/froalaTip.png);
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border: solid 1px #ffd666;
    border-radius: 6px;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}
.froalaCustomNoteOld {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    background-color: #e6f7ff;
    background-image: url(../src/images/froalaNote.png);
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border: solid 1px #69c0ff;
    border-radius: 6px;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
}
.froalaCustomWarningOld {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    background-color: #fff1f0;
    background-image: url(../src/images/froalaWarning.png);
    background-position: 9px 0px;
    background-repeat: no-repeat;
    border: solid 1px #ff7875;
    border-radius: 6px;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 60px;
} 
*/