body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout header {
  height: 80px;
  line-height: 80px;
  box-shadow: 0 15px 10px #f0f1f2;
  -webkit-box-shadow: 0 15px 10px #f0f1f2;
  z-index: 15;
}

.logo {
  /*width: 120px;
  height: 31px;*/
  line-height: 0px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 50px 16px 0;
  float: left;
  display: none;
}

.logo img{
  width: 200px;
  height: 48px;
}

.avatar {
  float: right;
  margin-right: 40px;
  cursor: pointer;
}

.mainSearchHolder {
  --width: 100%;
  width: calc(var(--width) - 250px);
}

.mainSearchBox {
  --width: 100%;
  width: calc(var(--width) - 150px);
  max-width: 600px;
  /*max-width: 350px;*/
}

@media (min-width:850px) {
  .logo { display: block; }
  .mainSearchHolder { width: calc(var(--width) - 255px); }
  .mainSearchBox { width: calc(var(--width) - 150px); }
}

.pgContent {
  margin-top: 30px;
}

.entryPageHeader .ant-page-header-heading {
  line-height: 3.7!important;
}

.entryPageHeader .ant-page-header-heading-title {
  font-size: 3vmin!important;
  line-height: 1.7!important;
  overflow: visible;
  white-space: pre-wrap; 
} 

.folderInfo {
  cursor: pointer;
}

.folderInfo svg {
  width: 60%;
  height: auto;
}

a .folderInfo {
  color: rgb(34, 59, 111);
}

a .folderInfo:hover {
  color: #1890ff;
  transition: all 0.3s;
}

.attributeFormRow {
  border: 1px solid #91d5ff;
  background-color: white;
  border-radius: 15px;
  /*margin-bottom: 10px;*/
}

.attributeFormRow section {
  display: flex;
  flex-direction: row;
}

.attributeFormRow aside {
  width: 60px;
  flex: 0;
  min-width: 60px;
  max-width: 60px;
}

.attributeFormRow aside div {
  height: 100%;
  display: grid;
  align-items: center;
  text-align: center;
}

.attributeFormRow main {
  padding: 10px 20px;
  border-left: 2px dashed #91d5ff;
}

.alterAttrFormRow {
  /* background-color: #e8e8e8; */
  background-color: #91d5ff;
  border-radius: 15px 0px 0px 15px;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  /* text-align: center; */
  padding: 20px;
}

.steps-action {
  margin-top: 24px;
}

.childTableTitle {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px 8px 16px;
  color: rgba(0, 0, 0, 0.85);
}

.droppableDivTagsArray {
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  border: 1.3px dashed lightgray;
  background-color: rgb(250, 250, 250);
}

.folder-card-title {
    position: relative;
    height: 3em;
    overflow: hidden;
}

.folder-card-title:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.5em;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
}


.entryPanelDiv {
  padding: 20px 10px;
}

.boldText {
  font-weight: bold;
}

.compareTableDiffRow {
  background-color: #fffbe6;
}

.compareTableNumber {
  float: right;
}

.ant-select-selection-item .searchBoxEntryTypeTag {
  display: none;
}

.lineagePlaceholder {
  width: 100%;
  height: calc(100vh - 340px);
}

.lineageLegend {
  position: fixed;
  right: 80px;
  bottom: 94px;
}

.lineageLegendItem .ant-list-item-meta-content {
  width: fit-content;
}

.folderIconPreview {
  width: 32px;
  margin-right: 20px;
  float: left;
}

.folderIconPreview svg{
  width: 100%;
  height: auto;
}

.advancedSearchRow {
  border: 10px solid #91d5ff;
  border-top: 1px dashed #91d5ff;
  border-bottom: 1px dashed #91d5ff;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  /*display: inline-block;*/
  min-width: 350px;
}

.advancedSearchRow section {
  display: flex;
  flex-direction: row;
}

.advancedSearchRow main {
  padding: 10px 20px;
  /*border-left: 2px dashed #91d5ff;*/
}

.advancedSearchDescBox th {
  width: 250px;
 
}
.searchFormItem{
  width: 100%;
}

.advancedSearchOperand {
  margin-top: 10px;
  margin-bottom: 10px;
}

.advancedSearchAddbuttons {
  margin-top: 10px;
}

.attSectionRowGutter {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*
Relations Attribute Tags
*/
.relAttTagDontTouchForRenderer {
  border-style: 'dashed';

}

.relAttTagForRenderer.ant-tag-has-color .anticon-close {
  color: rgba(0, 0, 0, 0.45);
}


/*
OLD FORM Hotfix - odebrat po vyřešní formů
*/
/*.ant-row .ant-legacy-form-item {
  display: block;
}*/
.ant-row.ant-legacy-form-item {
  display: block;
}
/*.ant-row .pgContent {
  display: block;
}*/
.ant-row.pgContent {
  display: block;
}
@media print {

  /*Pro Tisk*/
  body {
    font-family: Arial, Helvetica, sans-serif;
    color: black;
  }

  .ant-layout header {
    display: none;
    padding: 0, 0, 0, 0;
  }

  .mainContent {
    margin: 0;
    padding: 0px;
  }

  .ant-page-header-heading-extra {
    display: none;
  }

  .ant-row.ant-row-bottom {
    display: none;
  }

  .attSectionEmpty4Print {
    display: none;
  }

  .entryViewRelationPrint {
    display: none;
  }

  .ant-layout-footer {
    display: none;
  }

  .ant-divider.ant-divider-horizontal {
    display: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 0px;
    font-variant: tabular-nums;
    line-height: 0;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    border-top: 0px;
  }

  .entryAttDividerPrint {
    display: none;
  }
  .entryPageHeader .ant-page-header-heading-title {
    font-size: 3vmin!important;
    line-height: 1.7!important;
    overflow: visible;
    white-space: pre-wrap; 
  } 
}